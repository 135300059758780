<template>
  <div class="adviceBox">
    <!-- 顶部搜索框 -->
    <div class="usertop">
      <!-- 左侧搜索 -->
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="搜索">
            <el-input
              v-model="formInline.search_words"
              placeholder="输入搜索内容"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="formInline.status" placeholder="请选择">
              <el-option label="在用" value="1" />
              <el-option label="停用" value="0" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- 右侧按钮 -->
      <div>
        <el-button @click="search" class="color-main" type="primary">
          <i class="fa fa-search"></i>查询
        </el-button>
      </div>
    </div>
    <!-- 提示部分 -->
    <div class="usertop">
      <div style="width: 70%">
        <el-alert :title="title" type="warning" show-icon :closable="false" />
      </div>
      <div>
        <el-button @click="modParient(1)" class="color-green" type="primary">
          <i class="fa fa-plus-circle"></i>新增
        </el-button>
      </div>
    </div>

    <!-- 表格内容 -->
    <div>
      <el-table
        v-loading="loading"
        ref="multipleTableRef"
        :data="tableData"
        style="width: 100%"
        height="500"
        border
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column property="username" label="登录账号" />
        <el-table-column property="name" label="真实姓名" />
        <el-table-column property="mobile" label="手机号" />
        <el-table-column property="role.role_name" label="角色" />
        <el-table-column property="hospital.name" label="院区" />
        <!-- <el-table-column label="绑定签名图片">
          <template #default="scope">
            <div
              class="usericon"
              v-if="scope.row.autograph_image"
              @click="dialogVis(scope.row.autograph_image)"
            >
              <img :src="scope.row.autograph_image" />
            </div>
          </template>
        </el-table-column> -->
        <el-table-column label="状态">
          <template #default="scope">
            <el-tag v-if="scope.row.status == 1">{{
              scope.row.status_name
            }}</el-tag>
            <el-tag v-else type="danger">{{ scope.row.status_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template #default="scope">
            <el-button
              type="text"
              size="small"
              @click="passwords(scope.row.id)"
            >
              <span class="txfonts arrow-pass">密</span>
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="modParient(2, scope.row)"
            >
              <span class="txfonts modify">改</span>
            </el-button>
            <el-popconfirm
              v-if="scope.row.status == 1"
              title="是否确定停用?"
              @confirm="stopParient(scope.row.id)"
            >
              <template #reference>
                <el-button type="text" size="small">
                  <span class="txfonts stop">停</span>
                </el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm
              v-else
              title="是否确定启用?"
              @confirm="stopParient(scope.row.id)"
            >
              <template #reference>
                <el-button type="text" size="small">
                  <span class="txfonts binding">启</span>
                </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 放大图片弹窗 -->
    <div>
      <BaseDialog
        title
        :isshow="dialogVisible"
        @handleShow="
          (val) => {
            dialogVisible = val
          }
        "
        width="60%"
        height="600px"
      >
        <div style="margin: 10px 0">
          <img
            w-full
            :src="dialogImageUrl"
            alt="Preview Image"
            style="width: 100%; height: 520px"
          />
        </div>
      </BaseDialog>
    </div>

    <!-- 修改密码弹窗 -->
    <div>
      <BaseDialog
        title="修改密码"
        :isshow="show"
        @handleShow="
          (val) => {
            show = val
          }
        "
        width="20%"
        height="auto"
      >
        <div class="passclis">
          <el-form
            :inline="true"
            :model="Passform"
            class="demo-form-inline"
            label-width="100px"
            :rules="rules"
          >
            <el-form-item label="新密码" prop="password">
              <el-input
                v-model="Passform.password"
                type="password"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="确认密码" prop="password_confirm">
              <el-input
                v-model="Passform.password_confirm"
                type="password"
                placeholder="请输入"
              />
            </el-form-item>
          </el-form>
          <!-- 下划线 -->
          <el-divider />
          <!-- 底部按钮 -->
          <div class="displya_flex">
            <div></div>
            <el-row class="mb-4">
              <el-button @click="show = false">取消</el-button>
              <el-button type="primary" @click="comfing">确定</el-button>
            </el-row>
          </div>
        </div>
      </BaseDialog>

      <!-- 新增或修改弹窗 -->
      <div>
        <BaseDialog
          :title="addIndex == 1 ? '新增' : '修改'"
          :isshow="adshow"
          @handleShow="
            (val) => {
              adshow = val
            }
          "
          width="40%"
          height="auto"
        >
          <div class="User">
            <el-form
              :inline="true"
              :model="Userform"
              class="demo-form-inline"
              label-width="80px"
              :rules="rules"
            >
              <el-form-item label="真实姓名">
                <el-input v-model="Userform.name" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="登录账号" prop="username">
                <el-input v-model="Userform.username" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="手机号" prop="mobile">
                <el-input v-model="Userform.mobile" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="院区" prop="hospital_id">
                <el-select
                  v-model="Userform.hospital_id"
                  class="m-2"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in hospitalist"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="角色" prop="role_id">
                <el-select
                  v-model="Userform.role_id"
                  class="m-2"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in rolelist"
                    :key="item.id"
                    :label="item.role_name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="用户状态" prop="status">
                <el-select
                  v-model="Userform.status"
                  class="m-2"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in statuslist"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="密码" prop="password" v-if="addIndex === 1">
                <el-input
                  v-model="Userform.password"
                  type="password"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item
                label="确认密码"
                prop="password_confirm"
                v-if="addIndex === 1"
              >
                <el-input
                  v-model="Userform.password_confirm"
                  type="password"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="用户头像" style="width: 100%">
                <el-upload
                  class="avatar-uploader"
                  :on-change="handleChange"
                  :on-preview="handlePictureCardPreview"
                  :auto-upload="false"
                >
                  <img
                    v-if="Userform.user_icon"
                    :src="Userform.user_icon"
                    class="avatar"
                  />
                  <el-icon v-else class="avatar-uploader-icon">
                    <Plus />
                  </el-icon>
                </el-upload>
              </el-form-item>
            </el-form>
            <!-- 下划线 -->
            <el-divider />
            <!-- 底部按钮 -->
            <div class="displya_flex">
              <div></div>
              <el-row class="mb-4">
                <el-button @click="adshow = false">取消</el-button>
                <el-button type="primary" @click="addcomfig">确定</el-button>
              </el-row>
            </div>
          </div>
        </BaseDialog>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import BaseDialog from '@/components/Dialog/index.vue'
import service from '@/utils/request'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BaseDialog,
    Plus,
  },
  setup() {
    const state = reactive({
      formInline: {
        status: '1',
      },
      rolelist: [],
      fileList: [],
      Passform: {},
      dialogImageUrl: '',
      dialogVisible: false,
      addIndex: null,
      show: false,
      adshow: false,
      loading: true,
      Userform: {},
      hospitalist: [],
      statuslist: [
        { name: '在用', id: 1 },
        { name: '停用', id: 0 },
      ],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      tableData: [],
      title:
        '提示：如果添加的登录用户为科室职工，请先到【科室管理-->科室职工】添加职工信息，然后通过在姓名处选择相关职工来进行添加',
    })
    onMounted(() => {
      roles()
      tobody()
      hospitals()
    })
    // 获取列表
    const tobody = async () => {
      state.loading = true
      let data = {
        search_words: state.formInline.search_words,
        status: state.formInline.status,
        size: state.pageData.pageSize,
        current: state.pageData.currentPage,
      }
      let res = await service.post('/api/user/list', data)
      if (res.code === 0) {
        if (res.data.total > 0) {
          state.tableData = res.data.records
          state.pageData.totalSum = res.data.total
        } else {
          state.tableData = []
        }
      }
      state.loading = false
    }
    // 条数改变时
    const handleSizeChange = (e) => {
      state.pageData.pageSize = e
      tobody()
    }
    // 页数改变时
    const handleCurrentChange = (e) => {
      state.pageData.currentPage = e
      tobody()
    }
    const dialogVis = (url) => {
      state.dialogImageUrl = url
      state.dialogVisible = true
    }
    // 修改密码
    const passwords = (id) => {
      state.Passform = {}
      state.Passform.id = id
      state.show = true
    }
    // 表单校验规则
    const rules = reactive({
      password: [
        {
          required: true,
          message: '此项必填,请输入',
          trigger: 'blur',
        },
        { min: 3, max: 9, message: '长度在3~9之间', trigger: 'blur' },
      ],
      password_confirm: [
        {
          required: true,
          message: '此项必填,请输入',
          trigger: 'blur',
        },
        { min: 3, max: 9, message: '长度在3~9之间', trigger: 'blur' },
      ],
      username: [
        {
          required: true,
          message: '此项必填,请输入',
          trigger: 'blur',
        },
        { min: 3, max: 9, message: '长度在3~9之间', trigger: 'blur' },
      ],
      mobile: [
        {
          required: true,
          message: '此项必填,请输入',
          trigger: 'blur',
        },
        { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' },
      ],
      hospital_id: [
        {
          required: true,
          message: '此项必选,请选择',
          trigger: 'blur',
        },
      ],
      role_id: [
        {
          required: true,
          message: '此项必选,请选择',
          trigger: 'blur',
        },
      ],
      status: [
        {
          required: true,
          message: '此项必选,请选择',
          trigger: 'blur',
        },
      ],
    })
    // 修改密码确定
    const comfing = async () => {
      let res = await service.post('/api/user/pwd', state.Passform)
      if (res.code === 0) {
        ElMessage({
          message: '修改成功',
          type: 'success',
        })
        tobody()
        state.show = false
      }
    }
    // 查询
    const search = () => {
      tobody()
    }
    // 获取角色
    const roles = async () => {
      let res = await service.post('/api/role/list')
      if (res.code === 0) {
        if (res.data.total > 0) {
          state.rolelist = res.data.records
        } else {
          state.rolelist = []
        }
      }
    }
    // 新增
    const addcomfig = async () => {
      if (state.addIndex === 1) {
        let res = await service.post('/api/user/create', state.Userform)
        if (res.code === 0) {
          ElMessage({
            message: '添加成功',
            type: 'success',
          })
          tobody()
          state.adshow = false
        }
      } else {
        let res = await service.post('/api/user/edit', state.Userform)
        if (res.code === 0) {
          ElMessage({
            message: '修改成功',
            type: 'success',
          })
          tobody()
          state.adshow = false
        }
      }
    }
    // 院区列表
    const hospitals = async () => {
      let res = await service.post('/api/hospital/list', { parent_id: 0 })
      if (res.code === 0) {
        state.hospitalist = res.data
      }
    }
    // 停用
    const stopParient = async (id) => {
      let res = await service.post('/api/user/status', { id: id })
      if (res.code === 0) {
        ElMessage({
          message: '停用成功',
          type: 'success',
        })
        tobody()
      }
    }
    // 新增或修改收费座套
    const modParient = (index, row) => {
      state.Userform = {}
      if (index == 2) {
        state.disabled = true
        state.Userform = JSON.parse(JSON.stringify(row))
        state.Userform.hospital_id = row.hospital.id
        console.log(row)
      } else {
        state.disabled = false
      }
      state.addIndex = index
      state.adshow = true
    }
    // 上传照片
    const handleChange = async (e) => {
      let fileObj = e.raw
      let form = new FormData()
      form.append('img_file', fileObj)
      form.append('type', 'avatar')
      let res = await service.post('/api/common/upload_img', form)
      console.log(res)
      if (res.code === 0) {
        state.Userform.user_icon = res.data.url
      }
    }

    return {
      ...toRefs(state),
      rules,
      modParient,
      handleChange,
      tobody,
      handleSizeChange,
      handleCurrentChange,
      search,
      comfing,
      passwords,
      stopParient,
      roles,
      addcomfig,
      dialogVis,
      hospitals,
    }
  },
}
</script>

<style scoped lang="scss">
.adviceBox {
  height: 728px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 10px;
}
.usertop {
  margin-bottom: 8px;
  display: flex;
  height: 35px;
  justify-content: space-between;
  &:deep(.el-alert--warning.is-light) {
    background-color: rgba(49, 102, 174, 0.1);
    color: #3166ae;
  }
}
.el-form {
  margin-top: 0;
}
.arrow-pass {
  background: #c56002;
  color: #ffffff;
}
.displya_flex {
  display: flex;
  justify-content: space-between;
}
.passclis {
  margin-top: 20px;
  &:deep(.el-divider--horizontal) {
    margin: 10px 0;
  }
}
.User {
  margin-top: 20px;
  .el-form {
    .el-input {
      width: 195px;
    }
  }
}
.usericon {
  width: 100%;
  height: 50px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-fit: contain;
    object-fit: cover;
    object-fit: none;
    object-fit: scale-down;
  }
}
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.modify {
  background-color: #3166ae;
}
.stop {
  background-color: #f4a939;
}
.avatar-uploader-icon {
  border: 1px solid #ccc;
}
</style>
